import React, { ChangeEvent, useState } from 'react'
import { useLocale } from '../hooks/useLocale'
import { FieldLabel } from './common/FieldLabel'
import { FileUploader } from './common/FileUploader'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { TFileUploadHandler } from '../types'

type CertificateProps = TFileUploadHandler & {
  certificateInfo: string
}

export const Certificate = ({ uploadFile, certificateInfo }: CertificateProps) => {
  const { $t } = useLocale()
  const [certificateDescription, setCertificateDescription] = useState('')

  const downloadResponseKey = () => {
    setCertificateDescription('')
  }

  return (
    <section>
      <FieldLabel
        title={$t('certificate.title')}
      >
        <FileUploader
          fileUploadAction={$t('certificate.fileUploadAction')}
          fileUploadText={$t('certificate.fileUploadText')}
          uploadFile={uploadFile}
        />

        <FieldLabel
          title={$t('certificate.textareaLabel')}
          type="sub"
        >
          <InputTextarea
            style={{ minHeight: '8.25rem' }}
            autoResize={true}
            value={certificateInfo}
            onInput={(event: ChangeEvent<HTMLTextAreaElement>) => {
              setCertificateDescription(event.currentTarget.value)
            }}
          />
        </FieldLabel>
      </FieldLabel>
      <Button
        style={{ marginTop: 'calc(28rem/var(--bfs))' }}
        label={$t('certificate.responseKey')}
        onClick={downloadResponseKey}
      />
    </section>
  )
}
