/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useLocale } from '../hooks/useLocale'
import { ToastProvider } from '../hooks/useToastRef'
import { useApiRepository } from '../hooks/useApiRepository';
import { Header } from './Header'
import { Certificate } from './Certificate'
import { ActivationKey } from './ActivationKey'
import { useEffect, useState } from 'react';
import { IEntity } from '@netvision/lib-api-repo/dist/src/types';

export const Main = () => {
  const { $t } = useLocale();
  const { api } = useApiRepository()
  const [activations, setActivations] = useState([])
  const [certificateInfo, setSertificateInfo] = useState('')
  const [activationExpiresDate, setActivationExpiresDate] = useState<Date>()
  
  const uploadCertificate = (file: File) => {
    api.upload && api.upload(file, { id: 'Certificate', type: 'Client' })
      .then((response: any) => {
        setSertificateInfo(response.description)
      })
      .catch((error: Error) => console.error(error));
  }

  const uploadActivationKey = (file: File) => {
    api.upload && api.upload(file, { id: 'Activation', type: 'Client' })
      .then((response: any) => setActivationExpiresDate(new Date(response.date)))
      .catch((error: Error) => console.error(error));
  }

  // const fetchActivations = () => {
  //   api.getEntitiesList<IEntity>({
  //     limiter: { type: 'Client', id: 'Activation' }
  //   })
  //   .then((response) => console.log(response))
  //   .catch((error: Error) => console.error(error))
  // }

  // useEffect(() => {
  //   fetchActivations()
  // }, [activations])

  return (
    <ToastProvider position={'bottom-left'}>
      <div style={{ overflow: 'hidden' }}>
        <main css={mainCSS}>
          <Header title={$t('title')} />
          <div css={containerCSS}>
            <Certificate
              uploadFile={(file) => uploadCertificate(file)}
              certificateInfo={certificateInfo}
            />
            <ActivationKey
              uploadFile={(file) => uploadActivationKey(file)}
              activationExpiresDate={activationExpiresDate}
            />
          </div>
        </main>
      </div>
    </ToastProvider>
  )
}

const mainCSS = css`
  margin-top: calc(94rem/var(--bfs));
  margin-right: calc(30rem/var(--bfs));
  margin-left: calc(30rem/var(--bfs));
  margin-bottom: calc(30rem/var(--bfs));
  height: calc(100vh - 124rem/var(--bfs));
  width: calc(100vw - 60px);
  position: relative;
`

const containerCSS = css`
  margin-top: calc(30rem/var(--bfs));
  height: calc(100% - 7rem);
  overflow: auto;
  display: grid;
  gap: calc(28rem/var(--bfs));
  grid-template-columns: repeat(3, 1fr);
`
