export const localesRU = {
  title: 'Лицензия',
  certificate: {
    title: 'Сертификат',
    fileUploadText: 'Перетащите файл сертификата с компьютера или нажмите кнопку «загрузить файл»',
    fileUploadAction: 'Загрузить файл',
    textareaLabel: 'Информация о сертификате',
    responseKey: 'Скачать ответный ключ'
  },
  activationKey: {
    title: 'Ключ активации',
    fileUploadText: 'Перетащите файл ключа активации с компьютера или нажмите кнопку «загрузить файл»',
    fileUploadAction: 'Загрузить файл',
    dateLabel: 'Срок действия'
  },
  errors: {
    error: 'Ошибка',
    errorFileUpload: 'Не удалось загрузить файл'
  }
}
