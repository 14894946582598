import React from 'react'
import { useLocale } from '../hooks/useLocale'
import { FieldLabel } from './common/FieldLabel'
import { FileUploader } from './common/FileUploader'
import { Calendar } from 'primereact/calendar'
import { TFileUploadHandler } from '../types'

type ActivationKeyProps = TFileUploadHandler & {
  activationExpiresDate?: Date
}

export const ActivationKey = ({ uploadFile, activationExpiresDate }: ActivationKeyProps) => {
  const { $t } = useLocale()

  return (
    <section>
      <FieldLabel
        title={$t('activationKey.title')}
      >
        <FileUploader
          fileUploadAction={$t('activationKey.fileUploadAction')}
          fileUploadText={$t('activationKey.fileUploadText')}
          uploadFile={uploadFile}
        />

        <FieldLabel
          title={$t('activationKey.dateLabel')}
          type="sub"
        >
          <Calendar
            id="icon"
            value={activationExpiresDate}
            showIcon
            disabled
          />
        </FieldLabel>
      </FieldLabel>
    </section>
  )
}
