/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactNode } from 'react'

type FieldLabelProps = {
  children: ReactNode,
  title: string
  type?: 'basic' | 'sub'
}

export const FieldLabel = ({ children, title, type = 'basic' }: FieldLabelProps) => {
  return (
    <div
      className={`license__label ${type}`}
      css={labelCSS}
    >
      <div className={`label__title ${type}`}>{title}</div>
      {children}
    </div>
  )
}

const labelCSS = css`
  display: grid;
  
  &.basic {
    gap: calc(28rem/var(--bfs));
  }

  &.sub {
    gap: var(--spacer-xs)
  }

  .label__title {
    color: var(--text-color-secondary);

    &.basic {
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
    }
  
    &.sub {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
`
