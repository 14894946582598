export const localesEN = {
  title: 'License',
  certificate: {
    title: 'Certificate',
    fileUploadText: 'Drag the certificate file from your computer or click the «upload file» button',
    fileUploadAction: 'Upload file',
    textareaLabel: 'Certificate Information',
    responseKey: 'Download response key'
  },
  activationKey: {
    title: 'Activation key',
    fileUploadText: 'Drag the activation key file from your computer or click the «upload file» button',
    fileUploadAction: 'Upload file',
    dateLabel: 'Expiration date'
  },
  errors: {
    error: 'Error',
    errorFileUpload: 'Failed to upload file'
  }
}
