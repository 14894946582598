/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

type HeaderProps = {
  title: string
}

export const Header = ({ title }: HeaderProps) => {
  return (
    <header className="license__header" css={headerCSS}>
      <div className="license__title" css={headerTitleCSS}>
        <span>{title}</span>
      </div>
    </header>
  )
}

const headerCSS = css`
  border-bottom: 1px solid var(--text-color-secondary);
  position: relative;
  align-items: center;
  grid-area: header;
`

const headerTitleCSS = css`
  position: relative;
  top: calc(4rem/var(--bfs));
  padding-top: calc(16rem/var(--bfs));
  padding-bottom: calc(16rem/var(--bfs));
  display: inline-flex;
  border-bottom: 4px solid var(--primary-color);

  span {
    font-weight: 500;
    font-size: calc(24rem/var(--bfs));
    line-height: calc(30rem/var(--bfs));
    color: var(--text-color);
  }
`
