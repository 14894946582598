/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FileUpload, FileUploadErrorParams, FileUploadSelectParams } from 'primereact/fileupload'
import { useToastRef } from '../../hooks/useToastRef'
import { useLocale } from '../../hooks/useLocale'
import { TFileUploadHandler } from '../../types'

type FileUploaderProps = {
  fileUploadAction: string
  fileUploadText: string
  uploadFile: TFileUploadHandler['uploadFile']
}

export const FileUploader = ({
  fileUploadAction,
  fileUploadText,
  uploadFile
}: FileUploaderProps) => {
  const toast = useToastRef()
  const { $t } = useLocale()

  const onError = (err: FileUploadErrorParams) => {
    console.error(err)
    toast.current?.show({
      severity: 'error',
      summary: $t('errors.error'),
      detail: $t('errors.errorFileUpload')
    })
  }

  const onUpload = (event: FileUploadSelectParams) => {
    uploadFile(event.files[0]);
  }

  return (
    <FileUpload
      multiple={false}
      chooseLabel={fileUploadAction}
      emptyTemplate={fileUploadText}
      css={fileUploadCSS}
      className="p-flex p-flex-column"
      onError={onError}
      onSelect={onUpload}
    />
  )
}

const fileUploadCSS = css`
  display: flex;
  padding: calc(28rem/var(--bfs));
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--secondary-color-alt-1);
  border-radius: var(--border-radius);

  .p-fileupload-buttonbar {
    padding: 0;

    [aria-label="Upload"],
    [aria-label="Cancel"] {
      display: none;
    }
  }

  .p-fileupload-content {
    order: -1;
    padding: 0;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: calc(20rem/var(--bfs));
  }

  .p-fileupload-row {

    & > div {
      
      &:nth-of-type(2) {
        display: none;
      }

      &:last-of-type {
        width: auto;
        flex: 0;
      }
    }
  }
`
