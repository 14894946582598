import React from 'react'
import { LocaleProvider } from './hooks/useLocale'
import { ApiRepositoryProvider } from './hooks/useApiRepository';
import { TWidgetProps } from './types'
import { Main } from './components/Main'

export const Root = ({ props }: TWidgetProps) => {
  return (
    <LocaleProvider>
      <ApiRepositoryProvider lib={props.lib}>
        <Main />
      </ApiRepositoryProvider>
    </LocaleProvider>
  )
}
